import {
  HeadingPitchRange,
  Math as CesiumMath,
  Cartesian3,
  Cartographic,
  BoundingSphere,
  Matrix4,
} from "cesium";

export const FlyToHeadingPitchRange = () => {
  const offset = new HeadingPitchRange(
    CesiumMath.toRadians(0.0),
    CesiumMath.toRadians(-10.0),
    90.0
  );

  return offset;
};

export const FrustumFlyToFn = (viewer, item) => {
  let cartographic = Cartographic.fromCartesian(
    item.entity.boundingSphere.center
  );

  const longitude = CesiumMath.toDegrees(cartographic.longitude).toFixed(6);
  const latitude = CesiumMath.toDegrees(cartographic.latitude).toFixed(6);
  const height = cartographic.height;

  const long = parseFloat(longitude);
  const lat = parseFloat(latitude);

  viewer.camera.flyToBoundingSphere(
    new BoundingSphere(Cartesian3.fromDegrees(long, lat, height), 200),
    {
      duration: 0.5,
      complete: function() {
        const targetPosition = Cartesian3.fromDegrees(long, lat, height + 3);
        //0.79
        // console.log(
        //   "FlyToHeadingPitchRole",
        //   item.latLong.orientation[0],
        //   item.latLong.orientation[1],
        //   item.latLong.orientation[2]
        // );
        const nativePitch = item.latLong.orientation[1];
        const heading = item.latLong.orientation[0];
        const pitch =
          nativePitch -
          (nativePitch < 1
            ? CesiumMath.toRadians(50)
            : CesiumMath.toRadians(70));
        const range = 25;

        // Adjust the camera's offset
        const offset = new HeadingPitchRange(heading, pitch, range);

        // Use lookAt to center the target
        viewer.camera.lookAt(targetPosition, offset);
        viewer.camera.lookAtTransform(Matrix4.IDENTITY);
      },
    }
  );
};
