import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { updateKmlFeature } from "../../../../../../services/user.service";
import { SET_MESSAGE } from "../../../../../../actions/types";

const EditableTable = ({
  dispatch,
  tableName,
  htmlString,
  editInfoFlag,
  setEditInfoFlag,
  fid,
  setInfoLayerProperties,
  refreshLayer,
  type,
}) => {
  // Function to parse the HTML string and extract rows
  const parseTable = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const rows = doc.querySelectorAll("table tr");

    let tableData = [];
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td");
      if (cells.length === 2) {
        tableData.push({
          key: cells[0].textContent.trim(),
          value: cells[1].textContent.trim(),
        });
      }
    });

    return tableData;
  };

  // State for table data
  const [tableData, setTableData] = useState(parseTable(htmlString));

  // Handle input change
  const handleInputChange = (index, newValue) => {
    const updatedData = [...tableData];
    updatedData[index].value = newValue;
    setTableData(updatedData);
  };

  // Function to convert updated table data back to HTML string
  const convertToHtml = () => {
    let html = `<table style="display: table;"><tbody>`;
    tableData.forEach((row) => {
      html += `<tr><td>${row.key}</td><td>${row.value}</td></tr>`;
    });
    html += `</tbody></table>`;
    return html;
  };

  // Handle save action
  const handleSave = () => {
    const updatedHtmlString = convertToHtml();
    console.log("Updated HTML Table:", updatedHtmlString);
    // You can send this HTML string to your backend or store it
  };

  return (
    <>
      {editInfoFlag ? (
        <div className="infoPanelDescription">
          <table>
            <tbody>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
              {tableData.map((row, index) => (
                <tr key={index} hidden={row.key == "Label"}>
                  <td>{row.key}</td>
                  <td>
                    <input
                      disabled={
                        row.key == "fid" ||
                        row.key == "Name" ||
                        row.key.includes("Total ") ||
                        row.key == "Measurement Label"
                      }
                      type="text"
                      value={row.value}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className="infoPanelDescription"
          dangerouslySetInnerHTML={{
            __html: htmlString,
          }}
        ></div>
      )}
      {editInfoFlag ? (
        <Button
          onClick={() => {
            setEditInfoFlag(false);
          }}
          style={{ margin: "16px 17px", float: "right" }}
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
      ) : null}
      <Button
        onClick={() => {
          if (editInfoFlag) {
            console.log(tableData);
            let tempProperties = {};
            tableData.map((itemProp) => {
              if (
                !itemProp.key.includes("Total ") &&
                !itemProp.key.includes("Name") &&
                !itemProp.key.includes("Measurement Label") &&
                itemProp.key !== "Label"
              )
                tempProperties[itemProp.key] = itemProp.value;
            });
            updateKmlFeature(tableName, {
              fid: fid,
              type: type,
              properties: tempProperties,
            })
              .then((res) => {
                setInfoLayerProperties(convertToHtml());
                setEditInfoFlag(!editInfoFlag);
                refreshLayer();
                setTimeout(() => {
                  refreshLayer();
                }, 200);
                dispatch({
                  type: SET_MESSAGE,
                  payload: {
                    message: "Attributes updated",
                    severity: "success",
                    isOpen: true,
                  },
                });
              })
              .catch((res) => {
                dispatch({
                  type: SET_MESSAGE,
                  payload: {
                    message: "Wrong value added",
                    severity: "error",
                    isOpen: true,
                  },
                });
              });
          } else {
            setTableData(parseTable(htmlString));
            setEditInfoFlag(!editInfoFlag);
          }
        }}
        style={{ margin: "16px 17px", float: "right" }}
        variant="contained"
        color="primary"
      >
        {editInfoFlag ? "Save" : "Edit"}
      </Button>
    </>
  );
};

export default EditableTable;
