import React, { useState, useEffect, useRef } from "react";
import Drawer from "@material-ui/core/Drawer";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Box from "@material-ui/core/Box";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import { ChromePicker, HuePicker } from "react-color";
import Slider from "@material-ui/core/Slider";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../../../../actions/types";
import EditIcon from "@material-ui/icons/Edit";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioMui from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Switch from "react-switch";
import { Modal, Radio } from "antd";
import MenuItem from "@material-ui/core/MenuItem";
import IconStyles from "../../../icons-style";
import "./customStyles.module.css";
import UndoIcon from "@material-ui/icons/Undo";
import CloseIcon from "@material-ui/icons/Close";
import secureLocalStorage from "react-secure-storage";
import {
  checkSld,
  convertSld,
  GEOSERVER_WORKSPACE,
  hexToRgbA,
} from "../../../../common/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { BillboardGraphics, Color, VerticalOrigin } from "cesium";
import EditableTable from "./components/EditableTable";
import { Input } from "@material-ui/core";
import { updateKmlFeature } from "../../../../../services/user.service";

const useStyles = makeStyles({
  input: {
    fontSize: "12px",
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: 500, // Set the text color for the input field
    },
  },
});

let dateCollectedVar = null;
const InfoPanelComp = (props) => {
  const [infoLayerDes, setInfoLayerDes] = useState(props.infoLayerDes);
  const [editInfoFlag, setEditInfoFlag] = useState(false);
  const [editLabelFlag, setEditLabelFlag] = useState(false);
  const [labelText, setLabelText] = useState("");

  const [onChangeFlag, setOnChangeFlag] = useState(false);
  const [selectedPrimitive, setSelectedPrimitive] = useState(
    props.selectedPrimitive
  );
  const dateInputRef = useRef(null);
  // const [changedEntity, setChangedEntity] = useState(null);

  let changedStyleEntity = null;
  let uploadFilesImageryVar = [];
  const classes = useStyles();
  const [heightValue, setHeightValue] = useState(null);

  const onChangeHeightValue = (event) => {
    const inputValue = event.target.value;
    if (inputValue == "" || inputValue == "-" || inputValue == "+") {
      setHeightValue(inputValue);
    } else {
      props.setTileSetHeight(0);
      props.setTileSetHeightDefault(parseInt(inputValue));
      setHeightValue(inputValue);
    }
  };
  const updateStyleStateChange = () => {
    console.log("updateStyleStateChange", uploadFilesImageryVar);

    // console.log("changedStyleEntity", changedStyleEntity);
    // const tempArray = props.uploadFilesImagery;
    // tempArray.push(changedStyleEntity);
    // if (changedStyleEntity) {
    //   props.setUploadFilesImagery(tempArray);
    // }
    if (uploadFilesImageryVar.length > 0) {
      props.setUploadFilesImagery(uploadFilesImageryVar);
    }
  };

  const removeElementById = (id, array) => {
    let removedElement = null;
    const newArray = array.filter((item, index) => {
      if (item?.id === id) {
        removedElement = item;
        return false;
      }
      return true;
    });
    return { newArray, removedElement };
  };

  const updateKmlStyle = (datatosend) => {
    //Code To Refresh
    // const tempArray = props.uploadFilesImagery;
    // console.log("tempArray", tempArray);
    // const filterArray = removeElementById(props.layerPanelSelected, tempArray);
    // console.log("filterArray", filterArray);
    // props.setUploadFilesImagery(filterArray.newArray);
    // if (filterArray.removedElement) {
    //   changedStyleEntity = filterArray.removedElement;
    // }
    uploadFilesImageryVar = props.uploadFilesImagery;

    const objIndex = uploadFilesImageryVar.findIndex(
      (obj) => obj.id === props.layerPanelSelected
    );
    if (objIndex !== -1) {
      uploadFilesImageryVar[objIndex].altitudeMode = datatosend.altitudeMode;
      uploadFilesImageryVar[objIndex]["change"] = true;
    }
    props.setUploadFilesImagery([]);

    console.log("dataToSend", datatosend);

    props
      .updateVectorStyles(datatosend)
      .then((res) => {
        console.log("strokeColorRes", res);
        if (res.data.status == 200) {
          updateStyleStateChange();
        }
      })
      .catch((error) => {
        updateStyleStateChange();
      });
  };

  const handleSave = () => {
    if (props.editEntity) {
      Modal.confirm({
        title: "Are you sure you want to edit this layer?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: () => {
          props.createLayerHandler(
            document.getElementById("vectorNameVal").value
          );
        },
      });
    } else {
      props.dispatch({ type: LOADING_MESSAGE, isLoading: true });
      let attr = {
        description: infoLayerDes,
        color: "N/A",
        fillColor: props.fillColor,
        strokeColor: props.strokeColor,
        transparency: "N/A",
        strokeTransparency: String(props.strokeTrans),
        fillTransparency: String(props.fillTrans),
        tags: props.infoLayerTags,
        links: props.infoLayerLinks,
        altitudeMode: props.selectedAltitudeModeKml,
      };

      let attrFolder = {
        description: infoLayerDes,
        tags: props.infoLayerTags,
        links: props.infoLayerLinks,
      };

      if (props.infoLayerSelected === "Point Cloud") {
        const attrPointCloud = {
          pointcloudStyle: {
            pointCloudSize: props.pointCloudSize,
            styleMode: props.styleMode,
            styleClass: props.styleClass,
            selectedColorPallet: props.selectedColorPallet,
            heightSliderVal: props.heightSliderVal,
            altitudeMode: props.selectedAltitudeMode,
            tileSetHeightDefault: props.tileSetHeightDefault,
            tileSetHeight: props.tileSetHeight,
          },
        };
        props
          .updatePointCloudLayerAttributes(
            attrPointCloud,
            props.infoLayerSelectedId
          )
          .then((res) => {
            if (res) {
              if ((res.data.status = 200)) {
                props.errorDisplay("success", "Styles updated");

                selectedPrimitive._asset.style = attrPointCloud.pointcloudStyle;
              }

              console.log("sucessStyle", attrPointCloud.pointcloudStyle);
            } else {
              props.errorDisplay("error", "Request Unsuccessful");
            }
          });
      }

      if (props.infoLayerSelectedId) {
        let layerId = props.infoLayerSelectedId;
        if (typeof layerId === "string" && layerId.includes("folder_")) {
          props
            .updateLayerAttributesFolder(
              attrFolder,
              layerId.split("folder_")[1],
              props.infoLayerSelectedFeatureId
            )
            .then((res) => {
              if (res) {
                if ((res.data.status = 200)) {
                  props.errorDisplay(
                    "success",
                    "Folder attributes updated successfully"
                  );
                }
              } else {
                props.errorDisplay("error", "Request Unsuccessful");
              }
            });
        } else {
          if (props.infoLayerSelectedFeatureId) {
            props.updateLayerAttributes(attr, layerId, null);
          }
          props
            .updateLayerAttributes(
              attr,
              layerId,
              props.infoLayerSelectedFeatureId
            )
            .then((res) => {
              if (res) {
                if ((res.data.status = 200)) {
                  props.updateAllLayer(layerId, attr);
                  props.errorDisplay("success", "Layer updated successfully");
                }
              } else {
                props.errorDisplay("error", "Request Unsuccessful");
              }
            });
        }
      }
    }
  };

  useEffect(() => {
    setInfoLayerDes(props.infoLayerDes);
  }, [props.infoLayerDes]);

  useEffect(() => {
    if (
      !props.infoLayerProperties.includes("Total Points") &&
      props.infoLayerProperties.includes("Measurement Label")
    )
      setLabelText(props.infoLayerSelectedName);
  }, [props.infoLayerSelectedName]);
  useEffect(() => {
    if (
      props.infoLayerProperties.includes("Total Points") &&
      props.infoLayerProperties.includes("Label")
    )
      setLabelText(
        props.infoLayerProperties.split("Label</td><td>")[1].split("</td>")[0]
      );
  }, [props.infoLayerProperties]);
  useEffect(() => {
    if (props.dateCollectedDrawer.confirm && !props.dateCollectedDrawer.open) {
      props.infoPanelDateCollectedHandler(
        props.infoLayerSelectedName,
        dateCollectedVar
      );
    }
  }, [props.dateCollectedDrawer]);

  // useEffect(() => {
  //   let datatosend = {};
  //   if (
  //     props.layerPanelSelected &&
  //     (props.infoLayerSelected == "Polygon" ||
  //       props.infoLayerSelected == "LineString" ||
  //       props.infoLayerSelected == "MultiLineString" ||
  //       props.infoLayerSelected == "Point")
  //   ) {
  //     datatosend = {
  //       //layer: props.infoLayerSelectedWMSName,
  //       layerID: props.layerPanelSelected,
  //       altitudeMode: props.selectedAltitudeModeKml,
  //       userID: Number(props.getUserId()),
  //     };

  //     updateKmlStyle(datatosend);
  //   }
  // }, [props.showTerrain]);

  useEffect(() => {
    if (!props.openInfoDrawer && onChangeFlag) {
      handleSave();
    } else {
      setOnChangeFlag(false);
      setEditInfoFlag(false);
    }
  }, [props.openInfoDrawer]);

  useEffect(() => {
    let temp = null;
    let checkLayerWms = props.viewer?.imageryLayers?._layers.filter(
      (imgLayer) =>
        imgLayer?._imageryProvider?.itemId == props.layerPanelSelected
    );
    if (
      props.geoserverKmlsLoaded.filter(
        (geoItem) => geoItem.id == props.layerPanelSelected
      ).length
    ) {
      temp = props.geoserverKmlsLoaded.filter(
        (geoItem) => geoItem.id == props.layerPanelSelected
      )[0]?.tableName;
    } else if (checkLayerWms?.length) {
      temp = checkLayerWms[0]._imageryProvider.item?.tableName;
    }
    if (temp && props.openInfoDrawer) {
      fetch("https://geo.metageo.io/geoserver/rest/styles/" + temp + ".sld", {
        method: "GET",
        headers: {
          Authorization: "Basic " + "YWRtaW46Z2Vvc2VydmVy", // Replace with actual credentials
          Accept: "application/vnd.ogc.sld+xml",
        },
      })
        .then((response) => response.text())
        .then((sldText) => {
          props.parser
            .readStyle(sldText)
            .then(({ output: sldObject1 }) => {
              let sldObject = sldObject1;
              sldObject.rules = convertSld(sldObject1.rules);
              props.setKmlSld(sldObject);
              let checkPoly = checkSld(
                sldObject.rules,
                props.infoLayerSelectedFeatureId,
                "polygon"
              );
              let checkLine = checkSld(
                sldObject.rules,
                props.infoLayerSelectedFeatureId,
                "line"
              );
              if (checkPoly > -1) {
                props.setFillColor(
                  sldObject.rules[checkPoly].symbolizers[0]?.color
                );
                props.setFillTrans(
                  sldObject.rules[checkPoly].symbolizers[0]?.fillOpacity
                );
                props.setStrokeColor(
                  sldObject.rules[checkPoly].symbolizers[0]?.outlineColor
                );
                props.setStrokeTrans(
                  sldObject.rules[checkPoly].symbolizers[0]?.outlineWidth
                );
              }
              if (checkLine > -1) {
                props.setStrokeColor(
                  sldObject.rules[checkLine].symbolizers[0]?.color
                );
                props.setStrokeTrans(
                  sldObject.rules[checkLine].symbolizers[0]?.width
                );
              }
              sldObject.rules.forEach((styleRule) => {
                if (styleRule.name == "polygon_rule" && checkPoly == -1) {
                  props.setFillColor(styleRule.symbolizers[0]?.color);
                  props.setFillTrans(styleRule.symbolizers[0]?.fillOpacity);
                }
                if (
                  styleRule.name == "line_rule" &&
                  checkLine == -1 &&
                  checkPoly == -1
                ) {
                  props.setStrokeColor(styleRule.symbolizers[0]?.color);
                  props.setStrokeTrans(styleRule.symbolizers[0]?.width);
                }
                if (styleRule.name == "point_rule") {
                }
              });
            })
            .catch((error) => console.log(error));
          // Parse the SLD XML and extract styling information.
          // Then, apply those styles to your features.
        });
    }
  }, [props.layerPanelSelected, props.openInfoDrawer]);

  useEffect(() => {
    if (props.selectedPrimitive != null) {
      setSelectedPrimitive(props.selectedPrimitive);
    }
  }, [props.selectedPrimitive]);

  useEffect(() => {
    setOnChangeFlag(false);
  }, [props.infoLayerSelectedId, props.infoLayerSelectedFeatureId]);

  useEffect(() => {
    // if (heightValue != "" || heightValue != "-") {
    setHeightValue(parseInt(props.tileSetHeightDefault + props.tileSetHeight));
  }, [props.tileSetHeightDefault, props.tileSetHeight]);

  // useEffect(() => {
  //   let refreshItem;
  //   let newfiles = props.uploadFilesImagery.filter((delItem) => {
  //     if (delItem.id === props.layerPanelSelected) {
  //       refreshItem = delItem;
  //       return false;
  //     }
  //     return true;
  //   });
  //   props.setUploadFilesImagery(newfiles);
  //   console.log("refreshItem", refreshItem);
  //   if (refreshItem) {
  //     if (props.selectedAltitudeMode == 2) {
  //       refreshItem.url = refreshItem.url.replace(
  //         "altitudemode:absolute",
  //         props.showTerrain
  //           ? "altitudemode:relativeToGround"
  //           : "altitudemode:clampToGround"
  //       );
  //     } else {
  //       refreshItem.url = refreshItem.url.replace(
  //         props.showTerrain
  //           ? "altitudemode:relativeToGround"
  //           : "altitudemode:clampToGround",
  //         "altitudemode:absolute"
  //       );
  //     }
  //     newfiles.push(refreshItem);
  //     props.setUploadFilesImagery(newfiles);
  //     props.setVectorEditted(!props.vectorEditted);
  //   }
  // }, [props.selectedAltitudeMode, props.showTerrain]);

  return (
    <>
      {props.openInfoDrawer ? (
        <Box
          className={
            props.openInfoDrawer
              ? "Text-right Text-right-visible"
              : "Text-right Text-right-invisible"
          }
        >
          <IconButton
            className="infoClose"
            style={{
              left: "auto",
            }}
            onClick={props.handleInfoDrawerClose}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      ) : null}

      <Drawer
        // style={{ flexShrink: 0, width: 240 }}
        style={{
          zoom: 0.7,
        }}
        variant="persistent"
        anchor="right"
        open={props.openInfoDrawer}
        classes={{
          paper: props.classes.drawerPaper + " " + "mobileInfoPanel",
        }}
      >
        <div className={props.classes.drawerHeader}>
          <ToggleButton
            className="infoBack mobileBack"
            value="check"
            onClick={props.handleInfoDrawerClose}
            // selected={selected}
            // onChange={() => {
            //   setSelected(!selected);
            // }}
          >
            <ChevronRightIcon />
          </ToggleButton>
          {secureLocalStorage.getItem("user") !== "share" ? (
            <>
              {props.infoLayerSelectedFeatureId &&
                typeof props.infoLayerSelectedFeatureId !== "object" && (
                  <Button
                    onClick={() => {
                      const wfsUrl =
                        "https://geo.metageo.io/geoserver/wms?service=WFS&version=1.0.0&request=GetFeature&typeName=" +
                        GEOSERVER_WORKSPACE +
                        props.kmlSld.name +
                        "&filter=<Filter><PropertyIsEqualTo><PropertyName>fid</PropertyName><Literal>" +
                        props.infoLayerSelectedFeatureId +
                        "</Literal></PropertyIsEqualTo></Filter>" +
                        "&outputFormat=application%2Fjson";
                      let newfiles = props.uploadFilesImagery.map((delItem) => {
                        if (delItem.id === props.layerPanelSelected) {
                          delItem.show = !delItem.show;
                        }
                        return delItem;
                      });
                      props.setUploadFilesImagery(newfiles);
                      if (!props.editEntity) {
                        props.dispatch({
                          type: LOADING_MESSAGE,
                          isLoading: true,
                        });
                        fetch(wfsUrl)
                          .then((response) => response.json()) // Parse the JSON response
                          .then(async (geojsonData) => {
                            console.log(geojsonData);
                            document.getElementById("vectorNameVal").value =
                              props.infoLayerSelectedName;
                            if (
                              geojsonData.features[0].geometry.type ==
                                "LineString" ||
                              geojsonData.features[0].geometry.type ==
                                "MultiLineString"
                            ) {
                              props.setLineWidth(Number(props.strokeTrans));
                              let rgb1 = hexToRgbA(props.strokeColor);
                              let tempColor1 = {};
                              tempColor1.r = rgb1[0];
                              tempColor1.g = rgb1[1];
                              tempColor1.b = rgb1[2];
                              tempColor1.setColor =
                                "rgba(" +
                                tempColor1.r +
                                "," +
                                tempColor1.g +
                                "," +
                                tempColor1.b +
                                "," +
                                "1" +
                                ")";
                              tempColor1.hex = props.strokeColor;
                              props.setLineColor(tempColor1);
                              props.editVector(
                                "line",
                                geojsonData.features[0].geometry.coordinates,
                                props.kmlSld.name
                              );
                            } else if (
                              geojsonData.features[0].geometry.type ==
                                "Polygon" ||
                              geojsonData.features[0].geometry.type ==
                                "MultiPolygon"
                            ) {
                              let rgb = hexToRgbA(
                                props.fillColor ? props.fillColor : "#ffffff"
                              );
                              let tempColor = {};
                              tempColor.r = rgb[0];
                              tempColor.g = rgb[1];
                              tempColor.b = rgb[2];
                              let tempTrans = props.fillTrans
                                ? props.fillTrans
                                : props.fillColor
                                ? 1
                                : 0;
                              tempColor.setColor =
                                "rgba(" +
                                tempColor.r +
                                "," +
                                tempColor.g +
                                "," +
                                tempColor.b +
                                "," +
                                tempTrans +
                                ")";
                              tempColor.hex = props.fillColor
                                ? props.fillColor
                                : "#ffffff";
                              props.setFillTrans(tempTrans);
                              props.setPolygonBackground(tempColor);
                              props.setLineWidth(props.strokeTrans);
                              let rgb1 = hexToRgbA(props.strokeColor);
                              let tempColor1 = {};
                              tempColor1.r = rgb1[0];
                              tempColor1.g = rgb1[1];
                              tempColor1.b = rgb1[2];
                              tempColor1.setColor =
                                "rgba(" +
                                tempColor1.r +
                                "," +
                                tempColor1.g +
                                "," +
                                tempColor1.b +
                                "," +
                                "1" +
                                ")";
                              tempColor1.hex = props.strokeColor;
                              props.setLineColor(tempColor1);
                              function removeDuplicates(arr) {
                                const unique = new Set(arr.map(JSON.stringify)); // Convert arrays to strings and store in a Set
                                let tempArray = Array.from(unique).map(
                                  JSON.parse
                                ); // Convert back to arrays
                                tempArray.push(tempArray[0]);
                                return tempArray;
                              }
                              props.editVector(
                                "polygon",
                                geojsonData.features[0].geometry.coordinates[0]
                                  .length > 1
                                  ? removeDuplicates(
                                      geojsonData.features[0].geometry
                                        .coordinates[0]
                                    )
                                  : removeDuplicates(
                                      geojsonData.features[0].geometry
                                        .coordinates[0][0]
                                    ),
                                props.kmlSld.name
                              );
                            } else if (
                              geojsonData.features[0].geometry.type ==
                                "Point" ||
                              geojsonData.features[0].geometry.type ==
                                "MultiPoint"
                            ) {
                              props.editVector(
                                "point",
                                typeof geojsonData.features[0].geometry
                                  .coordinates === "object"
                                  ? [
                                      geojsonData.features[0].geometry
                                        .coordinates,
                                    ]
                                  : geojsonData.features[0].geometry
                                      .coordinates,
                                props.kmlSld.name
                              );
                            }
                          });
                      } else {
                        props.editVector(false, false, null);
                      }
                      // entity._entityCollection._entities._array[0]
                    }}
                    style={{ margin: "auto" }}
                    color="primary"
                  >
                    {!props.editEntity ? "Edit" : "Cancel"}
                  </Button>
                )}
              <Button
                onClick={() => {
                  handleSave();
                }}
                style={{ margin: "auto" }}
                color="primary"
              >
                {props.editEntity ? "Save Vector" : "Save"}
              </Button>
            </>
          ) : null}
        </div>
        <Tooltip title={props.infoLayerSelectedName} placement="top">
          <span
            className="infoText"
            style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
          >
            {props.infoLayerSelectedName && "Name: "}
            {props.infoLayerSelectedName.slice(0, 20).toLowerCase()}
            {props.infoLayerSelectedName.length > 20 && "..."}
          </span>
        </Tooltip>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Date Created:{" "}
          {props.dateInfoPanel.dateCreated != null &&
            props.dateInfoPanel.dateCreated.split("T")[0] +
              " " +
              props.dateInfoPanel.dateCreated.split("T")[1].substring(0, 8)}
        </span>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Date Updated:{" "}
          {props.dateInfoPanel.dateUpdated != null &&
            props.dateInfoPanel.dateUpdated.split("T")[0] +
              " " +
              props.dateInfoPanel.dateUpdated.split("T")[1].substring(0, 8)}
        </span>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Date Collected:
          <TextField
            id="date"
            style={{
              marginTop: "-10px",
              marginLeft: "10px",
              fontSize: "12px",
              color: "blue",
              userSelect: "none",
            }}
            className={classes.input + " infoText"}
            // label="Select Date",

            type="date"
            inputRef={dateInputRef} // Attach ref to the actual DOM input element
            onClick={() => {
              // Use ref to trigger focus programmatically
              if (dateInputRef.current) {
                dateInputRef.current.focus(); // Ensure focus on the input element
                dateInputRef.current.showPicker?.(); // Trigger date picker if supported
              }
            }}
            value={
              props.dateInfoPanel.dateCollected &&
              props.dateInfoPanel.dateCollected.split("T")[0]
            }
            // className={classes.textField}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onChange={(event) => {
              setOnChangeFlag(true);
              dateCollectedVar = event.target.value;
              let check = props.infoLayerSelectedId
                .toString()
                .includes("folder_");
              if (check) {
                props.handleDateCollectedDrawer({
                  ...props.dateCollectedDrawer,
                  open: true,
                });
              } else {
                props.infoPanelDateCollectedHandler(
                  props.infoLayerSelectedName,
                  dateCollectedVar
                );
              }
              // props.infoPanelDateCollectedHandler(
              //   props.infoLayerSelectedName,
              //   dateCollectedVar
              // );
            }}
          />
        </span>
        <span
          className="infoText"
          style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
        >
          Description
        </span>

        <div style={{ padding: "15px" }}>
          <TextareaAutosize
            value={infoLayerDes}
            onChange={(e) => {
              setOnChangeFlag(true);
              setInfoLayerDes(e.target.value);
            }}
            aria-label="Description"
            minRows={4}
            className="infoTextArea"
            placeholder="Description"
          />
        </div>
        {props.infoLayerProperties.includes("Measurement Label") ||
        (props.infoLayerProperties.includes("Total Points") &&
          props.infoLayerProperties.includes("Label")) ? (
          <>
            {" "}
            <span
              className="infoText"
              style={{ fontSize: 14, fontWeight: 500, padding: 10 }}
            >
              Label Text
            </span>
            <div style={{ padding: "15px" }}>
              <Input
                fullWidth
                disabled={!editLabelFlag}
                onChange={(e) => setLabelText(e.target.value)}
                value={labelText}
                placeholder="Label Text"
              />
              <br />
              {editLabelFlag ? (
                <Button
                  onClick={() => {
                    if (
                      props.infoLayerProperties.includes("Total Points") &&
                      props.infoLayerProperties.includes("Label")
                    ) {
                      setLabelText(
                        props.infoLayerProperties
                          .split("Label</td><td>")[1]
                          .split("</td>")[0]
                      );
                    } else {
                      setLabelText(props.infoLayerSelectedName);
                    }
                    setEditLabelFlag(false);
                  }}
                  style={{ margin: "16px 17px", float: "right" }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  if (editLabelFlag) {
                    let tempProperties = {};
                    if (
                      props.infoLayerProperties.includes("Total Points") &&
                      props.infoLayerProperties.includes("Label")
                    ) {
                      tempProperties["Label"] = labelText;
                    } else {
                      tempProperties["Name"] = labelText;
                    }
                    updateKmlFeature(props.kmlSld?.name, {
                      fid: props.infoLayerSelectedFeatureId,
                      type: props.infoLayerSelected,
                      properties: tempProperties,
                    })
                      .then((res) => {
                        setEditLabelFlag(!editLabelFlag);
                        let newfiles = props.uploadFilesImagery.map(
                          (delItem) => {
                            if (delItem.id === props.layerPanelSelected) {
                              delItem.show = !delItem.show;
                            }
                            return delItem;
                          }
                        );
                        props.setUploadFilesImagery(newfiles);
                        setTimeout(() => {
                          let newfiles = props.uploadFilesImagery.map(
                            (delItem) => {
                              if (delItem.id === props.layerPanelSelected) {
                                delItem.show = !delItem.show;
                              }
                              return delItem;
                            }
                          );
                          props.setUploadFilesImagery(newfiles);
                        }, 200);
                        props.setInfoLayerSelectedName(labelText);
                        props.dispatch({
                          type: SET_MESSAGE,
                          payload: {
                            message: "Label updated",
                            severity: "success",
                            isOpen: true,
                          },
                        });
                      })
                      .catch((res) => {
                        props.dispatch({
                          type: SET_MESSAGE,
                          payload: {
                            message: "Wrong value added",
                            severity: "error",
                            isOpen: true,
                          },
                        });
                      });
                  } else {
                    setEditLabelFlag(!editLabelFlag);
                  }
                }}
                style={{ margin: "16px 17px", float: "right" }}
                variant="contained"
                color="primary"
              >
                {editLabelFlag ? "Save" : "Edit"}
              </Button>
            </div>
          </>
        ) : null}
        <div style={{ padding: 12 }}>
          <Autocomplete
            multiple
            onChange={(a, b, c) => {
              setOnChangeFlag(true);
              if (b.length < 6) {
                props.setInfoLayerTags(b);
              } else {
                props.errorDisplay("error", "Maximum 5 Tags can be added");
              }
            }}
            id="tags-filled"
            options={[].map((option) => option.title)}
            freeSolo
            value={props.infoLayerTags ? props.infoLayerTags : []}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label="Tags"
                placeholder="Tags"
              />
            )}
          />
        </div>
        <div className="infoAccordion">
          <Accordion
            hidden={!props.infoLayerSelectedId}
            expanded={props.mediaAccordian}
            onChange={() => {
              props.setMediaAccordian(!props.mediaAccordian);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span
                style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
              >
                Media
              </span>
            </AccordionSummary>
            <AccordionDetails className="infoAccordion1">
              {secureLocalStorage.getItem("user") !== "share" ? (
                <IconButton
                  disabled={props.infoLayerSelectedMedia.length > 4}
                  onClick={(e) => {
                    e.preventDefault();
                    props.setUploadMediaDrawer(true);
                  }}
                  // selected={selected}
                  // onChange={() => {
                  //   setSelected(!selected);
                  // }}
                >
                  {" "}
                  <AddCircleOutlineIcon style={{ height: 40, width: 40 }} />
                </IconButton>
              ) : null}
              {props.infoLayerSelectedMedia.map((media, index) => {
                return (
                  <div key={`InfoLayer#${index}`} className="mapImageDiv">
                    <img
                      style={{ cursor: "pointer" }}
                      src={media.mediaKey}
                      onClick={() => {
                        props.setImageViewer(media.mediaKey);
                      }}
                      className="mapInfoImage"
                    />
                    <DeleteIcon
                      onClick={() => {
                        props.dispatch({
                          type: LOADING_MESSAGE,
                          isLoading: true,
                        });
                        props
                          .deleteMedia(media.id, media.layerId, media.featureId)
                          .then((res) => {
                            let infolayer = props.infoLayerSelectedMedia;
                            let filteredMedia = infolayer.filter(
                              (item) => item.id != media.id
                            );
                            props.updateAllLayerMedia(media.layerId, media.id);
                            props.setInfoLayerSelectedMedia(filteredMedia);
                            props.dispatch({
                              type: SET_MESSAGE,
                              payload: {
                                message: "Media Deleted",
                                severity: "success",
                                isOpen: true,
                              },
                            });
                            props.updateLocalStorage(
                              res?.data?.storageDetail?.storageCapacity,
                              res?.data?.storageDetail?.usedStorage
                            );
                          });
                      }}
                      className="mapImageButton"
                    />
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>

          <>
            {/* {secureLocalStorage.getItem("user") !== "share" ? ( */}
            <Accordion className="stylesAccordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span
                  style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
                >
                  Altitude Mode
                </span>
              </AccordionSummary>
              <AccordionDetails>
                {props.infoLayerSelected == "Point Cloud" && (
                  <>
                    <div style={{ padding: "15px 20px 15px 20px" }}>
                      <FormControl
                        size="small"
                        style={{ minWidth: "100%", marginBottom: 12 }}
                      >
                        <Tooltip
                          title={"Click Save to remember changes"}
                          placement="top"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-pallet"
                            MenuProps={{
                              classes: { paper: props.classes.menuPaper },
                            }}
                            value={props.selectedAltitudeMode}
                            onChange={(a, b, c) => {
                              setOnChangeFlag(true);
                              props.setHeight3DTileset(a.target.value);
                              //props.setSelectedAltitudeMode(a.target.value);
                            }}
                          >
                            <MenuItem value={"absolute"} key={1}>
                              Absolute
                            </MenuItem>
                            <MenuItem value={"clampToGround"} key={2}>
                              Clamp To Ground
                            </MenuItem>
                            <MenuItem value={"custom"} key={3}>
                              Custom
                            </MenuItem>
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </div>
                  </>
                )}
                {props.infoLayerSelected === "Point Cloud" &&
                  props.selectedAltitudeMode === "clampToGround" && (
                    <>
                      <div
                        // style={{ margin: "0 15px" }}
                        style={{
                          width: "340px",
                          // backgroundColor: "red",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ margin: "12px auto" }}>
                          <Button
                            // variant="contained"
                            style={{
                              textTransform: "initial",
                            }}
                            variant="outlined"
                            color="primary"
                            endIcon={
                              props.rayCastLoader ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null
                            }
                            onClick={() => {
                              props.rayCastCalculation(props.selectedPrimitive);
                            }}
                          >
                            Improve Clamp to Ground
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                {props.infoLayerSelected == "Point Cloud" &&
                  props.selectedAltitudeMode == "custom" && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Height
                      </span>
                      <div
                        // style={{ margin: "0 15px" }}
                        style={{ width: "340px" }}
                      >
                        <div style={{ margin: "12px" }}>
                          <TextField
                            size="small"
                            value={heightValue}
                            fullWidth
                            variant="outlined"
                            placeholder="Height"
                            type="number"
                            onChange={(event, value) => {
                              setOnChangeFlag(true);

                              onChangeHeightValue(event);
                            }}
                          />
                        </div>
                      </div>

                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Adjust 3D Height
                      </span>
                      <div style={{ padding: "10px 25px 10px 25px" }}>
                        <Slider
                          value={props.tileSetHeight}
                          onChange={(event, value) => {
                            setOnChangeFlag(true);
                            props.setTileSetHeight(value);
                          }}
                          min={-50}
                          step={1}
                          max={50}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                    </>
                  )}

                {props.infoLayerSelected == "folder" && (
                  <>
                    <span
                      style={{
                        fontSize: "1.06rem",
                        fontWeight: 500,
                        padding: 10,
                      }}
                    >
                      Bulk height adjustment for tilesets
                    </span>
                    <div style={{ padding: "15px 20px 15px 20px" }}>
                      <FormControl
                        style={{ minWidth: "100%", marginBottom: 12 }}
                      >
                        <Tooltip
                          title={"Click Save to remember changes"}
                          placement="top"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-pallet"
                            MenuProps={{
                              classes: { paper: props.classes.menuPaper },
                            }}
                            value={props.selectedAltitudeModeFolder}
                            onChange={(a, b, c) => {
                              setOnChangeFlag(true);
                              //props.setHeight3DTileset(a.target.value);
                              props.updateFolderBulkAltitudeFn(
                                props.infoLayerSelectedName,
                                a.target.value
                              );
                              //props.setSelectedAltitudeMode(a.target.value);
                            }}
                          >
                            <MenuItem value={"absolute"} key={1}>
                              Absolute
                            </MenuItem>
                            <MenuItem value={"clampToGround"} key={2}>
                              Clamp To Ground
                            </MenuItem>
                            <MenuItem value={"custom"} key={3}>
                              Custom
                            </MenuItem>
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </div>
                  </>
                )}

                {/* {props.infoLayerSelected == "folder" &&
                  props.selectedAltitudeMode == "custom" && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Height
                      </span>
                      <div
                        // style={{ margin: "0 15px" }}
                        style={{ width: "340px" }}
                      >
                        <div style={{ margin: "12px" }}>
                          <TextField
                            size="small"
                            value={
                              // props.tileSetHeight === 0
                              //   ? parseInt(props.tileSetHeightDefault)
                              //   : parseInt(
                              //       props.tileSetHeightDefault +
                              //         props.tileSetHeight
                              //     )
                              parseInt(
                                props.tileSetHeightDefault + props.tileSetHeight
                              )
                            }
                            fullWidth
                            variant="outlined"
                            placeholder="Height"
                            type="number"
                            onChange={(event, value) => {
                              setOnChangeFlag(true);
                              const inputValue = event.target.value;

                              console.log("inputValue", inputValue);

                              props.setTileSetHeight(0);

                              // Ensure the input is a valid number before updating state
                              if (
                                inputValue != "" &&
                                props.tilesetheight != 0
                              ) {
                                props.setTileSetHeightDefault(
                                  parseInt(inputValue)
                                );
                              } else if (props.tilesetheight === 0) {
                                props.setTileSetHeightDefault(0);
                              } else {
                                props.setTileSetHeightDefault(null);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Adjust 3D Height
                      </span>
                      <div style={{ padding: "10px 25px 10px 25px" }}>
                        <Slider
                          value={props.tileSetHeight}
                          onChange={(event, value) => {
                            setOnChangeFlag(true);
                            props.setTileSetHeight(value);
                          }}
                          min={-50}
                          step={1}
                          max={50}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                    </>
                  )} */}

                {(props.infoLayerSelected == "Point" ||
                  props.infoLayerSelected == "LineString" ||
                  props.infoLayerSelected == "MultiLineString" ||
                  props.infoLayerSelected == "Polygon" ||
                  props.infoLayerSelectedName?.toLowerCase().includes(".dxf") ||
                  props.infoLayerSelectedName?.toLowerCase().includes(".zip") ||
                  props.infoLayerSelectedName
                    ?.toLowerCase()
                    .includes(".json") ||
                  props.infoLayerSelectedName?.toLowerCase().includes(".gpx") ||
                  props.infoLayerSelectedName
                    ?.toLowerCase()
                    .includes(".geojson") ||
                  props.infoLayerSelectedName?.toLowerCase().includes(".kml") ||
                  props.infoLayerSelectedName
                    ?.toLowerCase()
                    .includes(".kmz")) && (
                  <>
                    <div style={{ padding: "15px 20px 15px 20px" }}>
                      <FormControl
                        style={{ minWidth: "100%", marginBottom: 12 }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select-pallet"
                          MenuProps={{
                            classes: { paper: props.classes.menuPaper },
                          }}
                          value={props.selectedAltitudeModeKml}
                          onChange={(a, b, c) => {
                            setOnChangeFlag(true);
                            props.setSelectedAltitudeModeKml(a.target.value);
                            let datatosend = {};
                            if (
                              props.layerPanelSelected &&
                              props.infoLayerSelected !== "Point Cloud" &&
                              (props.infoLayerSelected == "Polygon" ||
                                props.infoLayerSelected == "LineString" ||
                                props.infoLayerSelected == "MultiLineString" ||
                                props.infoLayerSelected == "Point" ||
                                props.infoLayerSelectedName
                                  ?.toLowerCase()
                                  .includes(".dxf") ||
                                props.infoLayerSelectedName
                                  ?.toLowerCase()
                                  .includes(".zip") ||
                                props.infoLayerSelectedName
                                  ?.toLowerCase()
                                  .includes(".json") ||
                                props.infoLayerSelectedName
                                  ?.toLowerCase()
                                  .includes(".gpx") ||
                                props.infoLayerSelectedName
                                  ?.toLowerCase()
                                  .includes(".geojson") ||
                                props.infoLayerSelectedName
                                  ?.toLowerCase()
                                  .includes(".kml") ||
                                props.infoLayerSelectedName
                                  ?.toLowerCase()
                                  .includes(".kmz"))
                            ) {
                              datatosend = {
                                //layer: props.infoLayerSelectedWMSName,
                                layerID: props.layerPanelSelected,
                                altitudeMode: a.target.value,
                                userID: Number(props.getUserId()),
                              };
                              uploadFilesImageryVar = props.uploadFilesImagery;

                              const objIndex = uploadFilesImageryVar.findIndex(
                                (obj) => obj.id === props.layerPanelSelected
                              );
                              if (objIndex !== -1) {
                                uploadFilesImageryVar[objIndex].altitudeMode =
                                  datatosend.altitudeMode;
                              }
                              props.setUploadFilesImagery([]);
                              setTimeout(() => {
                                props.setUploadFilesImagery(
                                  uploadFilesImageryVar
                                );
                              }, 100);
                              // updateKmlStyle(datatosend);
                            }
                          }}
                        >
                          <MenuItem value={"absolute"} key={1}>
                            Absolute
                          </MenuItem>
                          <MenuItem value={"clampToGround"} key={2}>
                            Clamp To Ground
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            {/* ) : null} */}
            {/* {secureLocalStorage.getItem("user") !== "share" ? ( */}
            <Accordion className="stylesAccordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span
                  style={{
                    fontSize: "1.06rem",
                    fontWeight: 600,
                    padding: 10,
                  }}
                >
                  Styles
                </span>
              </AccordionSummary>
              <AccordionDetails>
                {props.infoLayerSelected == "Polygon" && (
                  <>
                    <span
                      style={{
                        fontSize: "1.06rem",
                        fontWeight: 500,
                        padding: 10,
                      }}
                    >
                      Fill Color
                    </span>
                    <div style={{ padding: "15px 0px 15px 65px" }}>
                      <ChromePicker
                        color={props.fillColor}
                        onChangeComplete={(color) => {
                          setOnChangeFlag(true);
                          let tempWfsLayer = props.viewer.dataSources._dataSources.filter(
                            (dataItem) =>
                              dataItem.itemId == props.layerPanelSelected
                          );
                          if (tempWfsLayer.length) {
                            tempWfsLayer[0]._entityCollection._entities._array.forEach(
                              (k) => {
                                if (
                                  k.polygon &&
                                  k.properties.fid._value ==
                                    props.infoLayerSelectedFeatureId
                                ) {
                                  if (props.fillTrans) {
                                    k.polygon.material = Color.fromCssColorString(
                                      color.hex
                                    ).withAlpha(props.fillTrans);
                                  } else {
                                    k.polygon.material = Color.fromCssColorString(
                                      color.hex
                                    );
                                  }
                                }
                              }
                            );
                          }
                          let check = checkSld(
                            props.kmlSld.rules,
                            props.infoLayerSelectedFeatureId,
                            "polygon"
                          );
                          if (check > -1) {
                            props.kmlSld.rules[check].symbolizers[0].color =
                              color.hex;
                          } else {
                            props.kmlSld.rules.push({
                              name:
                                props.infoLayerSelectedFeatureId + "_polygon",
                              filter: [
                                "==",
                                "fid",
                                props.infoLayerSelectedFeatureId,
                              ],
                              symbolizers: [
                                {
                                  kind: "Fill",
                                  color: color.hex,
                                  fillOpacity: props.fillTrans
                                    ? props.fillTrans
                                    : 1,
                                  outlineColor: props.strokeColor
                                    ? props.strokeColor
                                    : "#ffffff",
                                  outlineWidth: props.strokeTrans
                                    ? props.strokeTrans
                                    : 1,
                                },
                              ],
                            });
                          }
                          // props.kmlSld.rules = props.kmlSld.rules.map(
                          //   (styleRule) => {
                          //     if (styleRule.name == "polygon_rule") {
                          //       styleRule.symbolizers[0].color = color.hex;
                          //     }
                          //     return styleRule;
                          //   }
                          // );
                          props.saveSld(props.kmlSld);
                          props.setFillColor(color.hex);
                        }}
                        disableAlpha={true}
                      />
                    </div>
                    <span
                      style={{
                        fontSize: "1.06rem",
                        fontWeight: 500,
                        padding: 10,
                      }}
                    >
                      Fill Transparency
                    </span>
                    <div style={{ padding: "10px 25px 10px 25px" }}>
                      <Slider
                        value={props.fillTrans}
                        onChangeCommitted={(event, value) => {
                          setOnChangeFlag(true);
                          let tempWfsLayer = props.viewer.dataSources._dataSources.filter(
                            (dataItem) =>
                              dataItem.itemId == props.layerPanelSelected
                          );
                          if (tempWfsLayer.length) {
                            tempWfsLayer[0]._entityCollection._entities._array.forEach(
                              (k) => {
                                if (
                                  k.polygon &&
                                  k.properties.fid._value ==
                                    props.infoLayerSelectedFeatureId
                                ) {
                                  if (value) {
                                    k.polygon.material = Color.fromCssColorString(
                                      props.fillColor
                                    ).withAlpha(value);
                                  } else {
                                    k.polygon.material = Color.fromCssColorString(
                                      props.fillColor
                                    );
                                  }
                                }
                              }
                            );
                          }
                          let check = checkSld(
                            props.kmlSld.rules,
                            props.infoLayerSelectedFeatureId,
                            "polygon"
                          );
                          if (check > -1) {
                            props.kmlSld.rules[
                              check
                            ].symbolizers[0].fillOpacity = value;
                          } else {
                            props.kmlSld.rules.push({
                              name:
                                props.infoLayerSelectedFeatureId + "_polygon",
                              filter: [
                                "==",
                                "fid",
                                props.infoLayerSelectedFeatureId,
                              ],
                              symbolizers: [
                                {
                                  kind: "Fill",
                                  color: props.fillColor
                                    ? props.fillColor
                                    : "#ffffff",
                                  fillOpacity: value,
                                  outlineColor: props.strokeColor
                                    ? props.strokeColor
                                    : "#ffffff",
                                  outlineWidth: props.strokeTrans
                                    ? props.strokeTrans
                                    : 1,
                                },
                              ],
                            });
                          }
                          // props.kmlSld.rules = props.kmlSld.rules.map(
                          //   (styleRule) => {
                          //     if (styleRule.name == "polygon_rule") {
                          //       styleRule.symbolizers[0].fillOpacity = value;
                          //     }
                          //     return styleRule;
                          //   }
                          // );
                          props.saveSld(props.kmlSld);
                          props.setFillTrans(value);
                        }}
                        min={0}
                        step={0.1}
                        max={1}
                        valueLabelDisplay="auto"
                        aria-labelledby="continuous-slider"
                      />
                    </div>
                  </>
                )}

                {props.infoLayerSelected == "Point Cloud" &&
                  props.showPointCloudStyleChkBx && (
                    // {true && (
                    <>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Point Cloud Size
                      </span>
                      <div style={{ padding: "10px 25px 10px 25px" }}>
                        <Slider
                          value={props.pointCloudSize}
                          onChangeCommitted={(event, value) => {
                            setOnChangeFlag(true);
                            props.setPointCloudSize(value);
                          }}
                          min={1}
                          step={1}
                          max={20}
                          valueLabelDisplay="auto"
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                      <span
                        style={{
                          fontSize: "1.06rem",
                          fontWeight: 500,
                          padding: 10,
                        }}
                      >
                        Select Classification Mode
                      </span>
                      <div style={{ padding: "10px 18px 10px 18px" }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={props.styleMode}
                            onChange={(e) => {
                              setOnChangeFlag(true);
                              props.changeClassificationStyleMode(e);
                            }}
                          >
                            <FormControlLabel
                              value="rgb"
                              control={
                                <RadioMui
                                  size="small"
                                  classes={{
                                    root: props.classes.radio,
                                    checked: props.classes.checked,
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  style={{ fontSize: "70%", color: "black" }}
                                >
                                  RGB
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="classification"
                              disabled={props.chkPointCloudClassStyle}
                              control={
                                <RadioMui
                                  size="small"
                                  classes={{
                                    root: props.classes.radio,
                                    checked: props.classes.checked,
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  style={{ fontSize: "70%", color: "black" }}
                                >
                                  Classification
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="height"
                              control={
                                <RadioMui
                                  size="small"
                                  classes={{
                                    root: props.classes.radio,
                                    checked: props.classes.checked,
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  style={{ fontSize: "70%", color: "black" }}
                                >
                                  Height
                                </Typography>
                              }
                            />
                            {/* <FormControlLabel
      value="disabled"
      disabled
      control={<RadioMui />}
      label="other"
    /> */}
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {props.styleMode === "height" && (
                        <>
                          <span
                            style={{
                              fontSize: "1.06rem",
                              fontWeight: 500,
                              padding: 10,
                            }}
                          >
                            Style By Height
                          </span>
                          <div style={{ padding: "10px 25px 10px 25px" }}>
                            <Slider
                              value={props.heightSliderVal}
                              onChange={(event, value) => {
                                setOnChangeFlag(true);
                                //setPointCloudSize(value);
                                console.log("valueDoubleSlider", value);
                                if (value[0] < value[1]) {
                                  props.setHeightSliderVal([
                                    Math.floor(value[0]),
                                    Math.floor(value[1]),
                                  ]);
                                }
                              }}
                              min={props.minzMaxz[0]}
                              // marks
                              step={
                                (props.minzMaxz[1] - props.minzMaxz[0]) / 1000
                              }
                              max={props.minzMaxz[1]}
                              valueLabelDisplay="auto"
                              // getAriaLabel={() => 'Minimum distance shift'}
                              disableSwap
                            />
                          </div>
                          {/* <span
                      style={{ fontSize: 12, padding: 10 }}
                    >
                    Select Color Pallet
                    </span> */}
                          <div className="stylePointCloudDiv">
                            <div className="styleItemDiv">
                              <div className="styleItem1">
                                <div className="labelClass1">Color Pallet:</div>
                              </div>

                              <div className="styleItem1">
                                <FormControl
                                  style={{ minWidth: 140, marginBottom: 12 }}
                                >
                                  <Select
                                    style={{ fontSize: 11 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select-pallet"
                                    value={props.selectedColorPallet.id}
                                    MenuProps={{
                                      classes: {
                                        paper: props.classes.menuPaper,
                                      },
                                    }}
                                    onChange={(a, b, c) => {
                                      setOnChangeFlag(true);
                                      props.colorPalletChangeHandler(a, b, c);
                                    }}
                                  >
                                    {props.heightColorList.map((k, i) => {
                                      return (
                                        <MenuItem
                                          value={i}
                                          key={k.colorPalletName + i}
                                        >
                                          {k.colorPalletName}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </div>
                              <div className="styleItem1">
                                <div className="labelClass1">
                                  <span
                                    class="dot"
                                    style={{
                                      backgroundColor:
                                        props.selectedColorPallet.color1,
                                    }}
                                  ></span>
                                  <span
                                    class="dot"
                                    style={{
                                      backgroundColor:
                                        props.selectedColorPallet.color2,
                                    }}
                                  ></span>
                                  <span
                                    class="dot"
                                    style={{
                                      backgroundColor:
                                        props.selectedColorPallet.color3,
                                    }}
                                  ></span>
                                  <span
                                    class="dot"
                                    style={{
                                      backgroundColor:
                                        props.selectedColorPallet.color4,
                                    }}
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {props.styleMode === "classification" && (
                        <>
                          <span
                            style={{
                              fontSize: "1.06rem",
                              fontWeight: 500,
                              padding: 10,
                            }}
                          >
                            Style Classification
                          </span>
                          <div className="stylePointCloudDiv">
                            {props.styleClass.map((k, i) => {
                              return (
                                <div
                                  className="styleItemDiv"
                                  key={"rowClass" + k.id}
                                  onMouseEnter={() =>
                                    props.setIsShownClassList({
                                      index: i,
                                      show: true,
                                    })
                                  }
                                  onMouseLeave={() =>
                                    props.setIsShownClassList({
                                      index: i,
                                      show: false,
                                    })
                                  }
                                >
                                  <div className="styleItem">
                                    {" "}
                                    <div className="labelClass">
                                      {k.className}
                                    </div>
                                  </div>
                                  <div className="styleItem">
                                    <label>
                                      <Switch
                                        onChange={(a, b, c) => {
                                          setOnChangeFlag(true);
                                          props.handleChangeSwitch(a, b, c);
                                        }}
                                        checked={k.active}
                                        height={8}
                                        id={k.id}
                                        width={40}
                                        className="react-switch"
                                        onHandleColor={k.color}
                                        offHandleColor={"#901da1"}
                                        handleDiameter={18}
                                      />
                                    </label>
                                  </div>
                                  <div
                                    className={["styleItem", "iconClass"].join(
                                      " "
                                    )}
                                  >
                                    {" "}
                                    <IconButton
                                      onClick={() => {
                                        console.log(
                                          "styleClass",
                                          props.styleClass
                                        );
                                        console.log("k.id", k.id);
                                        props.styleClassEditHandler(k);
                                      }}
                                      aria-label="edit"
                                    >
                                      {props.isShownClassList.index === i &&
                                      props.isShownClassList.show ? (
                                        <EditIcon />
                                      ) : null}
                                    </IconButton>
                                  </div>
                                  <div
                                    className={["styleItem", "iconClass"].join(
                                      " "
                                    )}
                                  >
                                    {" "}
                                    <IconButton
                                      onClick={() => {
                                        props.styleClassDeleteHandler(k);
                                      }}
                                      aria-label="delete"
                                    >
                                      {props.isShownClassList.index === i &&
                                      props.isShownClassList.show ? (
                                        <UndoIcon />
                                      ) : null}
                                    </IconButton>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {props.styleClassEdit.active &&
                        props.styleMode === "classification" && (
                          <>
                            <span
                              style={{
                                fontSize: "1.06rem",
                                fontWeight: 500,
                                padding: 10,
                              }}
                            >
                              Pick Classification Color
                            </span>
                            <span>
                              {" "}
                              <IconButton
                                style={{ float: "right" }}
                                aria-label="close"
                                //  className={classes.closeButton}
                                onClick={props.closeStyleClassHandler}
                              >
                                <CloseIcon />
                              </IconButton>
                            </span>
                            <div style={{ padding: "15px 0px 15px 65px" }}>
                              <ChromePicker
                                color={props.styleClassEdit.color}
                                onChangeComplete={(color) => {
                                  setOnChangeFlag(true);

                                  console.log("color", color.hex);
                                  props.onChangeClassEditColorHandler(
                                    color.hex
                                  );
                                }}
                                disableAlpha={true}
                              />
                            </div>
                          </>
                        )}
                    </>
                  )}
                {(props.infoLayerSelected == "Polygon" ||
                  props.infoLayerSelected == "LineString" ||
                  props.infoLayerSelected == "MultiLineString") && (
                  <>
                    <span
                      style={{
                        fontSize: "1.06rem",
                        fontWeight: 500,
                        padding: 10,
                      }}
                    >
                      Stroke Color
                    </span>
                    <div style={{ padding: "15px 0px 15px 65px" }}>
                      <ChromePicker
                        color={props.strokeColor}
                        onChangeComplete={(color) => {
                          setOnChangeFlag(true);
                          let tempWfsLayer = props.viewer.dataSources._dataSources.filter(
                            (dataItem) =>
                              dataItem.itemId == props.layerPanelSelected
                          );
                          if (tempWfsLayer.length) {
                            tempWfsLayer[0]._entityCollection._entities._array.forEach(
                              (k) => {
                                if (
                                  k.polygon &&
                                  k.properties.fid._value ==
                                    props.infoLayerSelectedFeatureId
                                ) {
                                  k.polygon.outline = true;
                                  k.polygon.outlineColor = Color.fromCssColorString(
                                    color.hex
                                  );
                                } else if (
                                  k.polyline &&
                                  k.properties.fid._value ==
                                    props.infoLayerSelectedFeatureId
                                ) {
                                  k.polyline.material = Color.fromCssColorString(
                                    color.hex
                                  );
                                }
                                // // Set the polyline material and width
                              }
                            );
                          }
                          if (props.infoLayerSelected == "Polygon") {
                            let check = checkSld(
                              props.kmlSld.rules,
                              props.infoLayerSelectedFeatureId,
                              "polygon"
                            );
                            if (check > -1) {
                              props.kmlSld.rules[
                                check
                              ].symbolizers[0].outlineColor = color.hex;
                            } else {
                              props.kmlSld.rules.push({
                                name:
                                  props.infoLayerSelectedFeatureId + "_polygon",
                                filter: [
                                  "==",
                                  "fid",
                                  props.infoLayerSelectedFeatureId,
                                ],
                                symbolizers: [
                                  {
                                    kind: "Fill",
                                    color: props.fillColor
                                      ? props.fillColor
                                      : "#ffffff",
                                    fillOpacity: props.fillTrans
                                      ? props.fillTrans
                                      : 1,
                                    outlineColor: color.hex,
                                    outlineWidth: props.strokeTrans
                                      ? props.strokeTrans
                                      : 1,
                                  },
                                ],
                              });
                            }
                          } else {
                            let check = checkSld(
                              props.kmlSld.rules,
                              props.infoLayerSelectedFeatureId,
                              "line"
                            );
                            if (check > -1) {
                              props.kmlSld.rules[check].symbolizers[0].color =
                                color.hex;
                            } else {
                              props.kmlSld.rules.push({
                                name:
                                  props.infoLayerSelectedFeatureId + "_line",
                                filter: [
                                  "==",
                                  "fid",
                                  props.infoLayerSelectedFeatureId,
                                ],
                                symbolizers: [
                                  {
                                    kind: "Line",
                                    color: color.hex,
                                    width: props.strokeTrans
                                      ? props.strokeTrans
                                      : 1,
                                  },
                                ],
                              });
                            }
                          }
                          // props.kmlSld.rules = props.kmlSld.rules.map(
                          //   (styleRule) => {
                          //     if (styleRule.name == "line_rule") {
                          //       styleRule.symbolizers[0].color = color.hex;
                          //     }
                          //     return styleRule;
                          //   }
                          // );
                          props.saveSld(props.kmlSld);
                          props.setStrokeColor(color.hex);
                        }}
                        disableAlpha={true}
                      />
                    </div>
                    <span
                      style={{
                        fontSize: "1.06rem",
                        fontWeight: 500,
                        padding: 10,
                      }}
                    >
                      Stroke Width
                    </span>
                    <div style={{ padding: "10px 25px 10px 25px" }}>
                      <Slider
                        value={props.strokeTrans}
                        onChangeCommitted={(event, value) => {
                          setOnChangeFlag(true);
                          let tempWfsLayer = props.viewer.dataSources._dataSources.filter(
                            (dataItem) =>
                              dataItem.itemId == props.layerPanelSelected
                          );
                          if (tempWfsLayer.length) {
                            tempWfsLayer[0]._entityCollection._entities._array.forEach(
                              (k) => {
                                if (
                                  k.polyline &&
                                  k.properties.fid._value ==
                                    props.infoLayerSelectedFeatureId
                                ) {
                                  k.polyline.width = value;
                                }
                                // // Set the polyline material and width
                              }
                            );
                          }
                          if (props.infoLayerSelected == "Polygon") {
                            let check = checkSld(
                              props.kmlSld.rules,
                              props.infoLayerSelectedFeatureId,
                              "polygon"
                            );
                            if (check > -1) {
                              props.kmlSld.rules[
                                check
                              ].symbolizers[0].outlineWidth = value;
                            } else {
                              props.kmlSld.rules.push({
                                name:
                                  props.infoLayerSelectedFeatureId + "_polygon",
                                filter: [
                                  "==",
                                  "fid",
                                  props.infoLayerSelectedFeatureId,
                                ],
                                symbolizers: [
                                  {
                                    kind: "Fill",
                                    color: props.fillColor
                                      ? props.fillColor
                                      : "#ffffff",
                                    fillOpacity: props.fillTrans
                                      ? props.fillTrans
                                      : 1,
                                    outlineColor: props.strokeColor
                                      ? props.strokeColor
                                      : "#ffffff",
                                    outlineWidth: value,
                                  },
                                ],
                              });
                            }
                          } else {
                            let check = checkSld(
                              props.kmlSld.rules,
                              props.infoLayerSelectedFeatureId,
                              "line"
                            );
                            if (check > -1) {
                              props.kmlSld.rules[
                                check
                              ].symbolizers[0].width = value;
                            } else {
                              props.kmlSld.rules.push({
                                name:
                                  props.infoLayerSelectedFeatureId + "_line",
                                filter: [
                                  "==",
                                  "fid",
                                  props.infoLayerSelectedFeatureId,
                                ],
                                symbolizers: [
                                  {
                                    kind: "Line",
                                    color: props.strokeColor
                                      ? props.strokeColor
                                      : "#ffffff",
                                    width: value,
                                  },
                                ],
                              });
                            }
                          }
                          // props.kmlSld.rules = props.kmlSld.rules.map(
                          //   (styleRule) => {
                          //     if (styleRule.name == "line_rule") {
                          //       styleRule.symbolizers[0].width = value;
                          //     }
                          //     return styleRule;
                          //   }
                          // );
                          props.saveSld(props.kmlSld);
                          props.setStrokeTrans(value);
                        }}
                        min={1}
                        step={1}
                        max={10}
                        valueLabelDisplay="auto"
                        aria-labelledby="continuous-slider"
                      />
                    </div>
                  </>
                )}
                {props.infoLayerSelected == "Point" && (
                  <>
                    <span
                      style={{
                        fontSize: "1.06rem",
                        fontWeight: 500,
                        padding: 10,
                      }}
                    >
                      Icons
                    </span>
                    <Radio.Group
                      style={{ padding: 15 }}
                      size="large"
                      // onChange={props.handleSelectedIconValue}
                      onChange={(event) => {
                        setOnChangeFlag(true);
                        let datatosend = {};
                        let tempWfsLayer = props.viewer.dataSources._dataSources.filter(
                          (dataItem) =>
                            dataItem.itemId == props.layerPanelSelected
                        );
                        if (tempWfsLayer.length) {
                          tempWfsLayer[0]._entityCollection._entities._array.forEach(
                            (k) => {
                              if (
                                k.position &&
                                k.properties.fid._value ==
                                  props.infoLayerSelectedFeatureId
                              ) {
                                k.billboard = new BillboardGraphics({
                                  image: window.location.host.includes(
                                    "localhost"
                                  )
                                    ? "http://localhost:3000/images/" +
                                      event.target.value +
                                      ".png"
                                    : props.ICON_URL +
                                      event.target.value +
                                      ".png", // URL to your custom icon
                                  scale: 0.5, // Adjust scale as needed
                                  verticalOrigin: VerticalOrigin.BOTTOM, // Align the icon relative to its position
                                });
                              }
                            }
                          );
                        }
                        // if (!props.kmlSld.rules.length) {

                        // }
                        let check = checkSld(
                          props.kmlSld.rules,
                          props.infoLayerSelectedFeatureId,
                          "point"
                        );
                        if (check > -1) {
                          props.kmlSld.rules[check].symbolizers[0].image =
                            props.ICON_URL + event.target.value + ".png";
                        } else {
                          props.kmlSld.rules.push({
                            name: props.infoLayerSelectedFeatureId + "_point",
                            filter: [
                              "==",
                              "fid",
                              props.infoLayerSelectedFeatureId,
                            ],
                            symbolizers: [
                              {
                                kind: "Icon",
                                image:
                                  props.ICON_URL + event.target.value + ".png",
                                size: 11,
                              },
                            ],
                          });
                        }
                        // props.kmlSld.rules = props.kmlSld.rules.map(
                        //   (styleRule) => {
                        //     if (styleRule.name == "point_rule") {
                        //       styleRule.symbolizers[0].image =
                        //         props.ICON_URL + event.target.value + ".png";
                        //     }
                        //     return styleRule;
                        //   }
                        // );
                        props.saveSld(props.kmlSld);
                      }}
                    >
                      <IconStyles />
                    </Radio.Group>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            {/* ) : null} */}
            <Accordion hidden>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span
                  style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
                >
                  Extruded Height
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ padding: "10px 25px 10px 25px" }}>
                  <Slider
                    // value={fillTrans}
                    onChangeCommitted={(event, value) => {
                      setOnChangeFlag(true);
                      let changedEntity;
                      let newfiles = props.uploadFiles.filter((entity) => {
                        if (entity.id == props.infoLayerSelectedId) {
                          entity.exth = value;

                          changedEntity = entity;
                        } else {
                          return entity;
                        }
                      });
                      props.setUploadfiles(newfiles);
                      newfiles.push(changedEntity);
                      props.setUploadfiles(newfiles);
                      props.setFillTrans(value);
                    }}
                    min={0}
                    step={50}
                    max={1000}
                    valueLabelDisplay="auto"
                    aria-labelledby="continuous-slider"
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </>
          <Accordion
            expanded={props.attrAccordian}
            onChange={() => {
              props.setAttrAccordian(!props.attrAccordian);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span
                style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
              >
                Attributes
              </span>
            </AccordionSummary>
            <AccordionDetails>
              {props.infoLayerSelectedFeatureId &&
              typeof props.infoLayerSelectedFeatureId !== "object" ? (
                <EditableTable
                  refreshLayer={() => {
                    let newfiles = props.uploadFilesImagery.map((delItem) => {
                      if (delItem.id === props.layerPanelSelected) {
                        delItem.show = !delItem.show;
                      }
                      return delItem;
                    });
                    props.setUploadFilesImagery(newfiles);
                  }}
                  dispatch={props.dispatch}
                  fid={props.infoLayerSelectedFeatureId}
                  setInfoLayerProperties={props.setInfoLayerProperties}
                  type={props.infoLayerSelected}
                  tableName={props.kmlSld?.name}
                  setEditInfoFlag={setEditInfoFlag}
                  htmlString={props.infoLayerProperties}
                  editInfoFlag={editInfoFlag}
                />
              ) : (
                <div
                  className="infoPanelDescription"
                  dangerouslySetInnerHTML={{
                    __html: props.infoLayerProperties,
                  }}
                ></div>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={props.linkAccordian}
            onChange={() => {
              props.setLinkAccordian(!props.linkAccordian);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span
                style={{ fontSize: "1.06rem", fontWeight: 600, padding: 10 }}
              >
                Links
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: 12, textAlign: "center" }}>
                <Autocomplete
                  multiple
                  onChange={(a, b, c) => {
                    setOnChangeFlag(true);
                    if (b.length < 6) {
                      if (
                        props.validURL(b[b.length - 1]) ||
                        c == "remove-option" ||
                        c == "clear"
                      ) {
                        props.setInfoLayerLinks(b);
                      } else {
                        props.errorDisplay("error", "Please add a valid link");
                      }
                    } else {
                      props.errorDisplay(
                        "error",
                        "Maximum 5 Links can be added"
                      );
                    }
                  }}
                  id="links-filled"
                  options={[].map((option) => option.title)}
                  freeSolo
                  value={props.infoLayerLinks ? props.infoLayerLinks : []}
                  renderTags={(value, getLinkProps) => {
                    return value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getLinkProps({ index })}
                      />
                    ));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Links"
                      placeholder="Links"
                    />
                  )}
                />
                {props.infoLayerLinks.map((item) => {
                  if (item.includes("youtube") || item.includes("youtu.be")) {
                    return (
                      <iframe
                        className="infoVideo"
                        src={`https://www.youtube.com/embed/${props.getYoutubeId(
                          item
                        )}`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    );
                  } else if (item.includes("matterport")) {
                    return (
                      <iframe
                        width="300"
                        height="300"
                        src={
                          item.includes("m=")
                            ? item
                            : `https://my.matterport.com/show/?m=${
                                item.split("/")[item.split("/").length - 1]
                              }`
                        }
                        frameborder="0"
                        allowfullscreen
                        allow="xr-spatial-tracking"
                      ></iframe>
                    );
                  } else {
                    return (
                      <a
                        className="infoLinks"
                        href={item.includes("http") ? item : "http://" + item}
                        target="_blank"
                      >
                        {item}
                      </a>
                    );
                  }
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Drawer>
    </>
  );
};

export default InfoPanelComp;
